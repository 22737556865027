<template>
  <div class="coupon-cont">
    <cube-tab-bar v-model="selectedLabel" show-slider class="taps">
      <cube-tab v-for="(item) in tabs" :label="item.label" :key="item.label">
      </cube-tab>
    </cube-tab-bar>
    <cube-tab-panels v-model="selectedLabel">
      <cube-tab-panel  label="有效服务">
        <div class="list" v-if="list1Ready == list1.length">
          <ul class="box shadow" v-for="(item, index) in list1" :key="index" @click="goDetails(item.contractItemId, item.contractNo, true, item.state)">
            <li class="box-header">
              <div class="icon">
                <img src="@/assets/image/icon-2.png" alt="">
              </div>
              <div class="name">{{item.contractName}}</div>
              <div class="val">{{item.stateName}}</div>
            </li>
            <li class="item">
              <div class="label">服务合同号：</div>
              <div class="text">{{item.contractNo}}</div>
            </li>
            <li class="item">
              <div class="label">生效日期：</div>
              <div class="text">{{item.startTime}}</div>
            </li>
            <li class="item">
              <div class="label">失效日期：</div>
              <div class="text">{{item.endTime}}</div>
            </li>
            <li class="item" v-show="item.aStateName">
              <div class="label">状态：</div>
              <div class="text">{{item.aStateName}}</div>
            </li>
            <li class="item" v-show="item.aapprovalInfos">
              <div class="text red">{{item.aapprovalInfos}}</div>
            </li>
          </ul>
          <div class="nodata" v-show="list1.length == 0">无数据</div>
        </div>
      </cube-tab-panel>
      <cube-tab-panel  label="失效服务">
        <div class="list">

          <ul class="box shadow"  v-for="(item, index) in list2" :key="index"  @click="goDetails(item.contractItemId, item.contractNo, false)">
            <li class="box-header">
              <div class="icon">
                <img src="@/assets/image/icon-2.png" alt="">
              </div>
              <div class="name">{{item.contractName}}</div>
              <div class="val">{{item.stateName}}</div>
            </li>
            <li class="item">
              <div class="label">服务合同号：</div>
              <div class="text">{{item.contractNo}}</div>
            </li>
            <li class="item">
              <div class="label">生效日期：</div>
              <div class="text">{{item.startTime}}</div>
            </li>
            <li class="item">
              <div class="label">失效日期：</div>
              <div class="text">{{item.endTime}}</div>
            </li>
          </ul>
          <div class="nodata" v-show="list2.length == 0">无数据</div>
        </div>
      </cube-tab-panel>
    </cube-tab-panels>
  </div>
</template>

<script>
export default {
  name: 'coupon',
  data () {
    return {
      imei: this.$route.params.imei,
      type: this.$route.query.type,
      list1: [], // 有效合同
      list2: [], // 失效合同
      selectedLabel: '有效服务',
      tabs: [{
        label: '有效服务', // 生效   待生效
      }, {
        label: '失效服务',
      }],
      applyList: [],
      applyInfo: {},

      list1Ready: 0,
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    /**
     * 前去申请
     */
    goAsk (type, id, contractNo, applyId) {
      if(type == 'add'){
        this.$router.push({
          path: '/ask/form',
          query: {
            id: id,
            contractNo: contractNo,
            type: type
          }
        })
      }else{
        this.$router.push({
          path: '/ask/auth',
          query: {
            id: id,
            contractNo: contractNo,
            type: type,
            applyId: applyId,
          }
        })
      }
    },
    /**
     * 获取申请单
     */
    getApply (id, no) {
      const vm = this;
      let postData = {
        contractItemId: id
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/apply/queryApplyByContractNo", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.applyList = response.data.body.contractApplyList ? response.data.body.contractApplyList : [];
            vm.applyInfo = vm.applyList[vm.applyList.length-1] ? vm.applyList[vm.applyList.length-1] : {};

            if(! vm.applyInfo.applyId){
              vm.goAsk('add', id, no, vm.applyInfo.applyId)
            }else if(vm.applyInfo.state != '3'){
              vm.goAsk('view', id, no, vm.applyInfo.applyId)
            }else if(vm.applyInfo.state == '3'){
              vm.goAsk('edit', id, no, vm.applyInfo.applyId)
            }

          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 前往详情
     */
    goDetails (id, no, valid, state) {
      if(this.type == 'ask'){
        if(valid){
          if(state == '2'){
            this.getApply(id, no)
          }else{
            this.$createToast({
              txt: '待生效服务不可申请',
              type: 'error'
            }).show()
          }
          // this.$router.push({
          //   path: '/ask/form',
          //   query: {
          //     id: id,
          //     contractNo: no
          //   }
          // })
        }else{
          this.$createToast({
            txt: '失效服务不可申请',
            type: 'error'
          }).show()
        }
      }else{
        this.$router.push('/service/details/' + id + '/' + no)
      }
    },
    /**
     * 获取合同列表
     */
    getInfo () {
      const vm = this;
      let postData = {
        mobile: this.$init.pass.phone
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/contractService/queryContractService", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.list1 = response.data.body.validServiceInfos ? response.data.body.validServiceInfos : [];
            vm.list1.forEach((e, i) => {
              vm.queryApply(e.contractItemId, i)
            })
            
            vm.list2 = response.data.body.invalidServiceInfos	? response.data.body.invalidServiceInfos	 : []
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 获取申请单
     */
    queryApply (contractItemId, index) {
      const vm = this;
      let postData = {
        contractItemId: contractItemId
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/apply/queryApplyByContractNo", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
    
            var applyList = response.data.body.contractApplyList ? response.data.body.contractApplyList : [];
  
            var applyInfo = applyList[applyList.length-1] ? applyList[applyList.length-1] : {};
         
            vm.list1[index].aStateName = applyInfo.stateName;
            vm.list1[index].aapprovalInfos = applyInfo.approvalInfos ? applyInfo.approvalInfos.join(',') : '';
            vm.list1Ready += 1;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 跳转页面
     */
    goPath (path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon-cont{
  min-height: 100vh;
  background: #F8F8F8;
}
.taps{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 90;
}
.list{
  padding-top: 126px;
}
.box-header{
  border-bottom: 1px solid #F4F4F4;
  display: flex;
  line-height: 40px;
  padding: 28px 0;
  font-size: 28px;
  img{
    display: block;
    height: 40px;
    margin-right: 12px;
  }
  .name{
    height: 40px;
    font-size: 32px;
    color: #000000;
    line-height: 40px;
    flex: 10;
  }
  .val{
    color: #1F955B;
    line-height: 40px;
  }
}

.box{
  margin: 0 30px 20px 30px;
  padding: 4px 24px 12px 24px;
  font-size: 28px;
  border-radius: 10px;
  background-color: #fff;
  .item{
    display: flex;
    &:first-child{
      border: none;
      .text{
        color: #1F955B;
        font-weight: 700;
      }
    }
    padding: 20px 0;
    line-height: 40px;
    .label{
      padding-right: 20px;
    }
    .text{
      flex: 10;
      text-align: right;
    }
  }
}
.btn{
  display: inline-block;
  font-size: 26px;
  width: 148px;
  border-radius: 30px;
  text-align: center;
  padding: 0;
  line-height: 48px;
  background-color: #1F955B;
}
.red{
  color: orange;
}
</style>